<template>

    <div class="app">

        <div class="Chatbot_w">

          <div class="componente1 vert-move"></div>
          <div class="sub_componente1 "></div> 

          <div class="top container-fluid">
            <div class="row">
                <div class="icon_W col-md-1">
                  <img class="icon_W2 " src="../assets/componente_26.png" alt="">
              </div>
              <div class="col-md-4">
                <div class="telefono " data-aos="zoom-in-up">
                  <img class="img_telefono" src="../assets/componente_27.png" alt="">
                </div>
              </div>  
              <div class="col-md-7">
                <div class="chat_w">
                  <h2 class="letras"><b> Chatbot en Whatsapp</b></h2>
                  <p class="letras_2">El asistente personal en Whatsapp que lleva el negocio por ti</p>
                  <div>

                    <div class=" row izquierda">
                      <div class="col-md-3" data-aos="zoom-in" > 
                        <div  class="alinear_cuadros">
                          <img class="apps2 " src="../assets/componente_4.png" alt="">
                          <p class="text-center mt-3 texto"><b> ventas</b></p>
                        </div>
                        
                      </div> 
                      <div class="col-md-3" data-aos="zoom-in">
                        <div  class="alinear_cuadros">
                          <img class="apps2 " src="../assets/componente_4.png" alt="">
                          <p class="text-center mt-3 texto" ><b> atención a clientes</b></p>
                        </div>
                      </div>
                    </div>    
                  </div>
                </div>
                <div class="text-left">
                  <button class="btn color-b" @click="$router.push('/Chatbot_w2')">Mas información</button>
                </div>
              </div>
            </div>
          </div>

        </div>  
    </div>
      

</template>

<script>
export default {
 name: 'Chatbot_w',
  components: {
    
  },
  data(){
      return{
          step: 1

      }
  },
  async mounted(){

  },
  methods:{
      goToStep(item){
          this.step=item

      },
      
  }
}
</script>
<style scoped>

.Chatbot_w {
  display:flex ;
  height: 750px;
  position: relative;
  
}
.componente1{
  background-image: url("../assets/componente_1.png");
  width:295px;
  height:250px;
  background-size: contain;
  background-repeat: no-repeat;
  top: 10%;

  
} 
.sub_componente1{
  background-image: url("../assets/componente_1.png");
  position: absolute;
  background-size: contain;
  background-repeat: no-repeat;
  width:275px;
  height:250px;
  right:0px ;
  top: 539px;
  transform: rotate(180deg);
}
.telefono{
  text-align:right; 
  margin-right: 5%;
  
  
}
.top{
  position: absolute;
  top: 20%;
  }
.icon_W{
  text-align: center;
}
.icon_W2{
  max-width: 250%;
  max-height: 250%;
}
.chat_w{
  text-align: left;
}
.letras{
  font-size: 40px;
  color: rgba(29, 10, 53, 0.815);
}
.letras_2{
  font-size: 25px;
}
.apps2{
    max-height:80%;
    max-width: 80%;
    
}
.color-b{
      background-color: rgb(49, 108, 235);
      color: rgb(242, 212, 251);
      border-radius: 18px;
      width: 400px;
      height: 70px;
      font-size: 25px;
      margin-top: 15%;
      }
      .color-b:hover{
        background-color: rgb(31, 88, 211);
        }
      .alinear_cuadros{
        text-align:center
      }

.img_telefono{
  max-height: 80%;
  max-width: 80%;
}
.izquierda{
  text-align: left;
}
.texto{
  font-size:20px
}
.vert-move:hover {
    -webkit-animation: mover 1s infinite  alternate;
    animation: mover 1s infinite  alternate;
}

@-webkit-keyframes mover {
    0% { transform: translateX(0); }
    100% { transform: translateX(-30px); }
}

@media only screen and (max-width: 780px) {
  .Chatbot_w {
  display:flex ;
  height: 1050px;
  position: relative;
  margin-top: -50px;

  
}
.icon_W{
  display: none;
}
.color-b{
      background-color: rgb(45, 101, 223);
      color: rgb(212, 223, 251);
      border-radius: 18px;
      width: 250px;
      height: 60px;
      font-size: 25px;
      margin-left: 10%;
      }

.componente1{
  top: 10%; 
  max-height:50%;
  max-width: 50%;
} 
.sub_componente1{
 display: none

}
.top{
  top: 25%;
 
}
.telefono{
  text-align:center; 
  margin-right: 0%;
  
}
.chat_w{
  text-align: center;
  margin-top: 10%;
}
.img_telefono{
  max-height: 50%;
  max-width: 50%;
}
.letras{
  font-size: 30px;

}
.letras_2{
  font-size: 22px;
}
.apps2{
    max-height:50%;
    max-width: 50%;
    
}

}
@media only screen and (max-width: 800px) and (min-width: 780px) {
  .img_telefono{
  max-height: 70%;
  max-width: 70%;
}
.componente1{
  background-image: url("../assets/componente_1.png");
  width:250px;
  height:230px;
  background-size: contain;
  background-repeat: no-repeat;
  top: 10%;
}
.icon_W2{
  max-width: 290%;
  max-height: 290%;
} 
.sub_componente1{
  width:250px;
  height:250px;
  top: 520px;
  
}
.letras{
  font-size: 30px;

}
.letras_2{
  font-size: 22px;
}
.texto{
  font-size:16px
}
.apps2{
    max-height:100%;
    max-width: 100%;
    
}

}
</style>